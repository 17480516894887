const initialState = {
	publishers:{
		ranges: [],
		count: 0,
		labels: []
	},
	Tags: {
		ranges: [],
		count: 0,
		labels: []
	},
	articles: {
		ranges: [],
		count: 0,
		labels: []
	}
}

export default function(state=initialState, action){
	switch(action.type){
		case 'DASHBOARDPUBS':
			return {...state, publishers: action.payload};
		case "DASHBOARDTAGS":
			return {...state, tags:action.payload};
		case "DASHBOARFARTICLES":
			return {...state, articles: action.payload};
		default:
			return state;
	}
}