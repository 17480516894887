const initialState ={
	menus: [],
}

export default function(state=initialState,action){
	switch(action.type){
		case 'SIDEBARMENUS':
			return {...state, menus: action.payload}
		default:
			return state;
			break;
	}
}