const initalState={
	init: false,
	links: []
}

export default function(state=initalState, action){
	switch(action.type){
		case 'INIT_GRID':
			return {...state, init: action.payload};
			break;
		case 'LINKS':
			return {...state, links: action.payload};
			break;
		default: 
			return state;
			break;
	}
}